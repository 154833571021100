<template>
    <ResetPassword :token="token" class="reset-password-form" />
</template>

<script>
    import ResetPassword from '@/components/ResetPassword.vue'

    export default {
        name: 'reset-password',
        components: {
            ResetPassword
        },
        computed: {
            token: function() {
                return this.$route.params.token
            }
        },
        mounted: function() {
            this.$ebus.$emit("title:set", this.$t("menu.resetPassword"))
        },
        destroyed: function() {
            this.$ebus.$emit("title:reset")
        }
    }
</script>

<style scoped lang="scss">   
    @import 'src/scss/main';

    .reset-password-form {
        margin-top: $container-top-margin;
    }
</style>