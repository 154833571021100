<template>    
    <b-form>
        <b-container>
            <b-form-group
                :invalid-feedback="invalidPasswordFeedback"
                :state="isPasswordValid"
                :label="$t('resetPassword.password')"
                label-for="id_password"
            >
                <b-form-input id="id_password" type="password" v-model="password" required :state="isPasswordValid"/>                
            </b-form-group>    

            <b-form-group
                :invalid-feedback="invalidRepeatFeedback"
                :state="isRepeatValid"
                :label="$t('resetPassword.repeat')"
                label-for="id_repeat"
            >
                <b-form-input id="id_repeat" type="password" v-model="repeat" required :state="isRepeatValid"/>
            </b-form-group>
        </b-container>
        
        <b-container>
            <ButtonRow>
                <button @click.prevent="reset" class="btn btn-success" type="submit" :disabled="!isValid">{{ $t("button.save") | uppercase }}</button>
            </ButtonRow>
        </b-container>
    </b-form>
</template>

<script>
    /* eslint-disable */
    import {CONFIG} from '../config.js'
    import ButtonRow from '@/components/ButtonRow.vue'

    export default {
        props: ["token"],
        data: function() {
            return {                
                password: null,
                repeat: null,
                passwordChanged: false,
                repeatChanged: false
            }
        },
        components: { ButtonRow },
        watch: {
            password: function(v) {
                this.passwordChanged = (v !== null);
            },
            repeat: function(v) {
                this.repeatChanged = (v !== null);
            }
        },
        computed: {
            invalidPasswordFeedback: function() {
                if (!this.passwordChanged) {
                    return '';
                }

                if (!this.password) {
                    return this.$t('resetPassword.noPasswordError');
                }

                return '';
            },
            isPasswordValid: function() {
                if (!this.passwordChanged) {
                    return null;
                }

                return this.invalidPasswordFeedback == '';
            },
            invalidRepeatFeedback: function() {
                if (!this.repeatChanged) {
                    return '';
                }

                if (!this.repeat) {
                    return this.$t('resetPassword.noPasswordError');
                } else if (this.repeat != this.password) {
                    return this.$t('resetPassword.notEqualPasswordsError');
                }

                return '';
            },
            isRepeatValid: function() {
                if (!this.repeatChanged) {
                    return null;
                }

                return this.invalidRepeatFeedback == '';
            },
            isValid: function() {
                return this.isPasswordValid && this.isRepeatValid;
            }
        },
        methods: {
            reset: function() {
                if (!this.isValid) {
                    return;
                }

                var self = this;

                this.$request("post", CONFIG.api.endpoints.resetPassword,
                    function() {
                        self.$ebus.$emit("msg:new", {type: "message-default", data: {text: self.$t("resetPassword.success"), variant: "success", persistent: true}})
                        self.$router.push("/login")
                    },
                    {
                        data: {
                            "new_password_": this.password,
                            "new_password_confirmation_": this.repeat
                        },
                        headers: {
                            'Authorization': "Bearer " + this.token,
                            'Content-Profile': 'platform'
                        }
                    }
                )
            }
        },
        filters: {
            uppercase: function(v) {
                return v.toUpperCase();
            }
        }
    }
</script>

<style scoped lang="scss">
</style>